<template>
  <v-row>
    <v-col cols="12" sm="12" v-if="!loading">
      <v-card elevation="10">
        <v-toolbar dense color="#003d6a" dark style="z-index: 9">
          <v-toolbar-title>{{ $t("reservations") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <date-range-picker
            v-model="searchBody"
            ref="picker"
            opens="left"
            singleDatePicker="range"
            :alwaysShowCalendars="false"
            :style="!isMobile() ? 'min-width: 350px' : ''"
            :ranges="ranges"
          >
            <template>
              {{ searchBody.startDate | date }} -
              {{ searchBody.endDate | date }}
            </template>
          </date-range-picker>
        </v-toolbar>
        <v-row>
          <v-col cols="12" sm="6" lg="12" md="12">
            <v-row>
              <v-col
                cols="6"
                sm="6"
                lg="2"
                md="4"
                v-for="(item, index) in reservationData"
                :key="index"
              >
                <router-link
                  :to="
                    '/reservation/index/?' +
                    'startDate=' +
                    moment(searchBody.startDate)
                      .add(3, 'hours')
                      .format('YYYY-MM-DD') +
                    '&&' +
                    'endDate=' +
                    moment(searchBody.endDate).format('YYYY-MM-DD') +
                    '&&' +
                    'status=' +
                    item.statusies
                  "
                >
                  <CCallout :color="item.status.color" style="cursor: pointer">
                    <small class="text-muted">{{ item.status.name }}</small
                    ><br />
                    <strong class="h4">{{ item.length }}</strong>
                    <br />
                    <strong class="h6">{{ item.price }}</strong>
                  </CCallout>
                </router-link>
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="12" lg="12">
            <BarChart
              :queries="searchBody"
              :labels="vendorLabels"
              :datasets="datasets"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-card v-if="!isPartner" elevation="10" class="mt-7">
        <v-toolbar color="#003d6a" dark dense>
          <v-toolbar-title> {{ $t("last_search") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn icon @click="getNowSearch()">
            <v-icon color="white">mdi-refresh</v-icon>
          </v-btn> -->

          <lottie-player
            src="https://assets4.lottiefiles.com/packages/lf20_ua3gryvm.json"
            background="transparent"
            speed="1"
            style="width: 60px; height: 60px"
            loop
            autoplay
          ></lottie-player>
        </v-toolbar>

        <v-simple-table fixed-header height="500px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Alış</th>
                <th class="text-left">İade</th>
                <th class="text-center">Partner</th>
                <th class="text-center">Araç</th>
                <th class="text-right">Arama</th>
                <th class="text-right"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in nowSearchRowData" :key="item._id">
                <td>
                  <strong>{{ item.pickup_location_name }}</strong>
                  <br /><small>{{
                    moment(item.pickup_datetime).format("DD.MM.YYYY HH:mm")
                  }}</small>
                </td>
                <td>
                  <strong>{{ item.return_location_name }}</strong>
                  <br /><small>{{
                    moment(item.return_datetime).format("DD.MM.YYYY HH:mm")
                  }}</small>
                </td>
                <td class="text-center text-caption">{{ item.parameters?.partnerBrand }}</td>
                <td class="text-center">{{ item.resultCarCount }}</td>
                <td class="text-right">
                  <small>{{
                    moment(item.createdAt).local().format("DD.MM.YYYY HH:mm")
                  }}</small>
                </td>
                <td class="text-right">
                  <a
                    :href="
                      APP_URL +
                      'arac-kiralama/' +
                      item.pickup_location +
                      '/ara?sID=' +
                      item._id
                    "
                    target="_blank"
                    ><v-icon color="primary">mdi-arrow-right</v-icon></a
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-row>
        <v-col cols="12" md="6">
          <v-card v-if="!isPartner" class="mt-7" elevation="10">
            <v-toolbar color="#003d6a" dense dark>
              <v-toolbar-title>{{ $t("notifications") }} </v-toolbar-title>
            </v-toolbar>
            <v-col cols="12" sm="12" lg="12">
              <div
                v-for="(info, index) in infos"
                :key="index"
                style="cursor: pointer"
                class="m-2"
                @click="clickInfo(info)"
              >
                <div class="progress-group-header">
                  <CIcon :name="info.icon" class="progress-group-icon" />
                  {{ info.name }}
                  <span class="ml-auto font-weight-bold">{{ info.count }}</span>
                </div>
                <div class="progress-group-bars">
                  <CProgress
                    class="progress-xs"
                    :value="info.value"
                    color="warning"
                  />
                </div>
              </div>
            </v-col> </v-card
        ></v-col>
        <v-col cols="12" md="6" v-if="!isPartner">
          <v-card class="mt-7" elevation="10">
            <v-toolbar color="#003d6a" dense dark>
              <v-toolbar-title
                >{{ $t("fullCredit_billing_status") }}
              </v-toolbar-title>
            </v-toolbar>
            <v-col cols="12" sm="12">
              <router-link
                v-for="(info, index) in fullCreditBillingStatus"
                :key="index"
                style="cursor: pointer"
                :to="'/reservation/index/?' + 'accountingStatus=' + info._id"
              >
                <div class="progress-group-header">
                  {{ $t("accountingStatus." + info._id) }}
                  <span class="ml-auto font-weight-bold">{{ info.count }}</span>
                </div>
                <div class="progress-group-bars">
                  <CProgress
                    class="progress-xs"
                    :value="info.value"
                    color="warning"
                  />
                </div>
              </router-link>
            </v-col> </v-card
        ></v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-card v-if="!isPartner" class="mt-7" elevation="10">
            <v-toolbar color="#003d6a" dense dark>
              <v-toolbar-title>{{ $t("invoices_transfer") }} </v-toolbar-title>
            </v-toolbar>
            <v-col cols="12" sm="12">
              <div
                v-for="(info, index) in invoices"
                :key="index"
                style="cursor: pointer"
                @click="clickInfo(info)"
              >
                <div class="progress-group-header">
                  <CIcon :name="info.icon" class="progress-group-icon" />
                  {{ info.name }}
                  <span class="ml-auto font-weight-bold">{{ info.count }}</span>
                </div>
                <div class="progress-group-bars">
                  <CProgress
                    class="progress-xs"
                    :value="info.value"
                    color="warning"
                  />
                </div>
              </div>
            </v-col>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card v-if="!isPartner" class="mt-7" elevation="10">
            <v-toolbar color="#003d6a" dense dark>
              <v-toolbar-title>{{ $t("payment_transfer") }} </v-toolbar-title>
            </v-toolbar>
            <v-col cols="12" sm="12">
              <div
                v-for="(info, index) in paymentStatus"
                :key="index"
                style="cursor: pointer"
                @click="clickInfo(info)"
              >
                <div class="progress-group-header">
                  <CIcon :name="info.icon" class="progress-group-icon" />
                  {{ info.name }}
                  <span class="ml-auto font-weight-bold">{{ info.count }}</span>
                </div>
                <div class="progress-group-bars">
                  <CProgress
                    class="progress-xs"
                    :value="info.value"
                    color="warning"
                  />
                </div>
              </div>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <div class="w-100" v-else>
      <gogo-loading />
    </div>
  </v-row>
</template>

<script>
import axios from "axios";
import GogoLoading from "../components/GogoLoading.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import BarChart from "../components/Dashboard/Chart.vue";
import LocationInfos from "../components/Dashboard/LocationInfos.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import store from "../store";
import moment from "moment";
moment.locale("tr");
export default {
  name: "Dashboard",
  components: {
    GogoLoading,
    vSelect,
    DateRangePicker,
    BarChart,
    LocationInfos,
  },
  filters: {
    date(date) {
      return new Intl.DateTimeFormat("en-US").format(date);
    },
  },
  mounted() {
    store.getters.socket.on("SEARCH_LOG", (data) => {
      this.addRowData(data);
    });
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);

    let today = new Date();
    today.setHours(3, 0, 0, 0);
    let todayEnd = new Date();
    todayEnd.setHours(11, 59, 59, 999);

    let yesterdayStart = new Date();
    yesterdayStart.setDate(today.getDate() - 1);
    yesterdayStart.setHours(3, 0, 0, 0);

    let yesterdayEnd = new Date();
    yesterdayEnd.setDate(today.getDate() - 1);
    yesterdayEnd.setHours(11, 59, 59, 999);

    let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1, 3);
    let thisMonthEnd = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0,
      11,
      59,
      59,
      999
    );
    return {
      APP_URL: process.env.VUE_APP_URL,
      ranges: {
        [this.$t("today")]: [today, todayEnd],
        [this.$t("yesterday")]: [yesterdayStart, yesterdayEnd],
        [this.$t("this_month")]: [thisMonthStart, thisMonthEnd],
        [this.$t("this_year")]: [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999),
        ],
        [this.$t("last_month")]: [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999),
        ],
      },

      // now search props
      nowSearchRowData: [],
      //
      options: {
        vendors: [],
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      //charts props
      reservationData: [],
      vendorLabels: [],
      datasets: [],
      infos: [],
      invoices: [],
      paymentStatus: [],
      //charts pro ps

      loading: false,
      searchBody: {
        vendor: "",
        startDate,
        endDate,
      },
    };
  },
  computed: {
    isPartner() {
      return this.$store.state.GetServerData?.role == "PARTNER";
    },
  },
  watch: {
    "searchBody.startDate": function (value) {
      this.getReservationData();
    },
    "searchBody.vendor": function (value) {
      this.getReservationData();
    },
    "searchBody.endDate": function (value) {
      this.getReservationData();
      return value;
    },
    "$i18n.locale": function (value) {
      window.location.reload();
    },
  },
  created() {
    this.getReservationData();
    this.itemsVendor();
    this.getNowSearch();
    window.dataLayer?.push({
      event: "myEvent",
    });
  },
  methods: {
    addRowData(data) {
      this.nowSearchRowData = [data, ...this.nowSearchRowData];
    },
    async getNowSearch() {
      try {
        const { data } = await axios.get(
          process.env.VUE_APP_API_URL + "admin/dashboard/nowSearch",
          {
            withCredentials: true,
          }
        );
        this.nowSearchRowData = data;
      } catch (error) {}
    },
    clickInfo(info) {
      let routeData = this.$router.resolve(info?.route || {});

      window.open(routeData.href, "_blank");
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },

    async getReservationData() {
      this.loading = true;
      try {
        const { data } = await axios.post(
          process.env.VUE_APP_API_URL + "admin/dashboard",
          this.searchBody,
          {
            withCredentials: true,
            headers: {
              lang: this.$store.state.locale || "tr",
            },
          }
        );
        this.reservationData = data.reservations;
        this.vendorLabels = data.labels;
        this.datasets = data.datasets;
        this.infos = data.infos;
        this.invoices = data.invoicesStatus;
        this.fullCreditBillingStatus = data.fullCreditBillingStatus;
        this.paymentStatus = data.paymentStatus;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    async itemsVendor() {
      try {
        let vendors = await axios.get(
          process.env.VUE_APP_API_URL + "admin/vendor?sort=1",
          {
            withCredentials: true,
          }
        );
        this.options.vendors = vendors.data.vendorList;
      } catch (error) {}
    },
  },
};
</script>
<style lang="scss">
.form-control {
  display: flex;
  justify-content: center;
  font-size: 13px !important;
  color: black;
}

.daterangepicker {
  color: black;
  min-width: initial !important;
}
</style>
